<template>
    <Header></Header>
    <section class="wpo-contact-section section-padding static-hero-s2 mac-margin-top" style="height: auto!important;">
        <div class="container" v-if="loaded">
            <div class="wpo-contact-section-wrapper">
                <div name="fade-y" mode="out-in">
                    <div class="wpo-contact-form-area" :key="1" >
                        <div class="wpo-section-title-s2" v-if="step !== 3">
                            <div v-if="ferme" style="color: orange;font-weight: bold;font-size:130%">FERME</div>
                            <div class="section-title-simg">
                                <img src="@/assets/images/section-title2.png" alt="">
                            </div>
                        </div>
                        <div class="contact-validation-active" novalidate="novalidate" v-if="step !== 3">
                            <div class="col-12 d-flex flex-column flex-md-row align-items-center">
                                <label class="from-label ">Serez-vous présent ?</label>
                                <div class="d-flex ms-md-auto">
                                    <div class="wrapper">
                                        <input type="radio" name="select" id="option-1" v-model="willBeThere" value="true" @change="changeLogementPresentQuizz($event)">
                                        <input type="radio" name="select" id="option-2" v-model="willBeThere" value="false" @change="changeLogementPresentQuizz($event)">
                                        <label for="option-1" class="option option-1">
                                            <div class="dot"></div>
                                            <span>OUI</span>
                                        </label>
                                        <label for="option-2" class="option option-2">
                                            <div class="dot"></div>
                                            <span>NON</span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 d-flex align-items-center flex-column flex-md-row" v-if="present && invite_accompagner">
                                <label class="from-label ">Serez-vous accompagner ?</label>
                                <div class="d-flex ms-md-auto">
                                    <div class="wrapper">
                                        <input type="radio" name="select2" id="option-3" v-model="willBeAccompanied" value="true" @change="changeLogementAccompaniedQuizz($event)">
                                        <input type="radio" name="select2" id="option-4" v-model="willBeAccompanied" value="false" @change="changeLogementAccompaniedQuizz($event)">
                                        <label for="option-3" class="option option-1">
                                            <div class="dot"></div>
                                            <span>OUI</span>
                                        </label>
                                        <label for="option-4" class="option option-2">
                                            <div class="dot"></div>
                                            <span>NON</span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 d-flex flex-column align-items-center " v-if="present && accompanied && nomInviteAccompagner">
                                <div class="d-flex flex-column flex-md-row">
                                    <label class="from-label ">Veuillez entrer le nom de votre cavalier(e)</label>
                                    <div class="d-flex ms-md-auto ">
                                        <input type="text" class="form-control text-center" autofocus v-model="nom_cavalier">
                                    </div>
                                </div>
                            </div>
                            <!--         LOGEMENT QUIZZ               -->
                            <div class="col-12 d-flex align-items-center flex-column flex-md-row" v-if="present && start_logementquizz && cantHaveLogement">
                                <label class="from-label">Voulez-vous qu'on vous reserve une chambre d'hotel ?</label>
                                <div class="d-flex ms-md-auto">
                                    <div class="wrapper">
                                        <input type="radio" name="select3" id="option-5" v-model="want_a_hotel_room_p" value="true">
                                        <input type="radio" name="select3" id="option-6" v-model="want_a_hotel_room_p" value="false">
                                        <label for="option-5" class="option option-1">
                                            <div class="dot"></div>
                                            <span>OUI</span>
                                        </label>
                                        <label for="option-6" class="option option-2" @click="want_a_hotel_room_p = false">
                                            <div class="dot"></div>
                                            <span>NON</span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div v-for="(item,questionKey) in logementQuizzQuestions" :key="questionKey+1">
                                <div class="card shadow mt-2" v-if="present && want_a_hotel_room && start_logementquizz && cantHaveLogement">
                                    <div class="card-body text-center">
                                        <h4>{{item.question}}</h4>
                                        <div class="row" style="margin: 20px 0px">
                                            <div class="col-12 p-2 col-md-6"  v-for="(response,responseKey) in item.responses" :key="responseKey">
                                                <div :class="'mac-response '+(!item.choosedResponse.includes(responseKey)?'':'mac-response-choosed')" @click="choseLogement(questionKey,responseKey)">
                                                    {{response.libelle}}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="submit-area d-flex justify-content-center mt-3">
                                <button type="submit" class="theme-btn-s4 d-flex align-items-center" :disabled="present === ''" @click="validerCavalier()">
                                    {{ dejaRepondue ? "Re-envoyer  ma réponse" : "Envoyer ma reponse" }} <i class="fa fa-spinner fa-spin ms-1" v-if="sendingResponse"></i>
                                </button>
                            </div>
                        </div>
                        <div class="wpo-section-title-s2" v-if="step === 3">
                            <div class="section-title-simg">
                                <img src="/assets/images/section-title2.png" alt="">
                            </div>
                            <h3>Pour te permettre de partager ces moments avec nous, tu peux continuer à accéder à cette plateforme pour avoir des informations sur l'évènement ou pour contribuer à sa réalisation, Merci</h3>
                            <div class="section-title-img">
                                <div class="round-ball"></div>
                            </div>
                            <div class="submit-area d-flex justify-content-center mt-5">
                                <button type="submit" class="theme-btn-s4 d-flex align-items-center" @click="this.$router.go(this.$router.currentRoute)"> Changer ma  reponse </button>
                            </div>
                        </div>

                        <div class="border-style"></div>
                    </div>

                </div>

                <div class="vector-1">
                    <img src="/assets/images/contact/1.png" alt="">
                </div>
                <div class="vector-2">
                    <img src="/assets/images/contact/2.png" alt="">
                </div>
            </div>
        </div>
        <div class="container d-flex justify-content-center align-items-center h-100" style="padding: 150px 0px" v-if="!loaded">
            <h1><i class="fa fa-spinner fa-spin"></i></h1>
        </div>
    </section>

    <Footer></Footer>
</template>

<script>
import Header from "./SubComponents/Header";
import Footer from "./SubComponents/Footer";
export default {
    name: "RespondToInvitation",
    components: {Footer, Header},
    data:function () {
        return {
            step:0,
            present:'',
            accompanied:'',
            nom_cavalier:'',
            start_logementquizz:false,
            // cantHaveLogement:false,
            logementQuizzQuestions:'',
            want_a_hotel_room:'',
            choosedResponse:'',
            sendingResponse:false,
            cantHaveLogement:false,
            ferme:false,
            dejaRepondue:false,
            loaded:false,
        };
    },
    // watch:{
    //     present:{
    //         handler:function (val, oldVal) {
    //             this.startLogementQuizz(val && !this.invite_accompagner && this.cantHaveLogement)
    //         }
    //     },accompanied:function (val) {
    //        this.startLogementQuizz(this.present && this.cantHaveLogement)
    //     }
    // },
    computed:{
        invite_accompagner:function () {
            return this.$store.state.invite_accompagner;
        },
        nomInviteAccompagner:function () {
            return this.$store.state.nomInviteAccompagner;
        }
        ,willBeThere:{
            get:function () {
                return this.present;
            },
            set:function (val) {
                this.present = (val == "true")
            }
        }
        ,willBeAccompanied:{
            get:function () {
                return this.accompanied;
            },
            set:function (val) {
                this.accompanied = (val == "true")
            }
        }
        ,want_a_hotel_room_p:{
            get:function () {
                return this.want_a_hotel_room;
            },
            set:function (val) {
                this.want_a_hotel_room = (val == "true")
            }
        }
    },
    methods:{
        changeLogementPresentQuizz:function (event) {
            this.startLogementQuizz(event.target.value && this.cantHaveLogement)
        },
        changeLogementAccompaniedQuizz:function (event) { // eslint-disable-line no-unused-vars
            // this.startLogementQuizz(this.present && this.cantHaveLogement)
        }
        ,willBePresent:function (response) {
            this.present = response;
            if (response && this.invite_accompagner){
                this.step = 1;
            }else{
                this.step = 3
            }
        },
        willBeAcompanied:function (response) {
            this.accompanied = response
            if (this.nomInviteAccompagner){
                this.step = 2
            }else{
                this.step = 3
            }
        },
        validerCavalier:function () {
            if (!this.ferme){
                this.sendingResponse = true
                let responses = [];
                for (let i = 0;i < this.logementQuizzQuestions.length;i++){
                    let response=[];
                    for (let j = 0;j < this.logementQuizzQuestions[i].choosedResponse.length;j++){
                        response.push(this.logementQuizzQuestions[i].responses[this.logementQuizzQuestions[i].choosedResponse[j]].slug)
                    }
                    responses.push({
                        slug_question:this.logementQuizzQuestions[i].slug,
                        slug_reponse:response
                    })
                }
                this.$store.commit("setReponseInvitation",{present:this.present,accompanied:this.accompanied,cavalier:this.nom_cavalier})
                this.axios.post("/repondre_invitation",{
                    slug_invitation:this.$store.state.invitation.slug,
                    reponse:{
                        present:this.present,
                        accompanied:this.accompanied,
                        nom_cavalier:this.nom_cavalier,
                        veut_une_chambre:this.want_a_hotel_room,
                        reponses_quizz_chambre:responses
                    }
                }).then((response) => {
                    let result = response.data;
                    if(result.error != null) {
                        this.$store.commit("showPopup",result.error)
                    }else{
                        if (result.success != null) this.$store.commit("showPopup",result.success)
                        this.step = 3
                    }
                }).catch(error => {

                    if(error.response?.status == 500){
                        this.$store.commit("showPopup","Une erreur s'est produite veuillez réessayer plus tard")
                    }else{
                        this.$store.commit("showPopup",error.response?.data?.errors)
                    }
                }).finally(() => this.sendingResponse = false)
            }
        },
        updateResponse:function () {
            if(this.present === false || this.accompanied === false){
                this.step = 3
            }
        },
        startLogementQuizz:function (val) {
            this.start_logementquizz = val
        },
        choseLogement:function (questionKey,responseKey){
            if (!this.logementQuizzQuestions[questionKey].choosedResponse.includes(responseKey)){
                this.logementQuizzQuestions[questionKey].choosedResponse.push (responseKey)
            }else{
                let i = this.logementQuizzQuestions[questionKey].choosedResponse.findIndex((r)=>r==responseKey)
                this.logementQuizzQuestions[questionKey].choosedResponse.splice(i,1)
            }
            // this.logementQuizzQuestions[questionKey].choosedResponse = responseKey
        }
    },
    mounted() {
        this.axios.get("/mariage/je-sais-pas/"+this.$store.state.invitation.slug).then((response) => {
            if (response.data.data.dateFermetureInvitation != null)this.ferme = ((new Date(response.data.data.dateFermetureInvitation)).getTime() < (new Date()).getTime());
            // this.logementQuizzQuestions = response.data.data.logement.questions
            this.$store.commit("setIviteAccompagner",(response.data.data.nomInviteAccompagner == "Oui"))
            this.$store.commit("setNomIviteAccompagner",(response.data.data.nomInviteAccompagner == "Oui"))

            let reponseBillet = response.data.data.reponseBillet;
            this.dejaRepondue = reponseBillet.dejaRepondu
            if (reponseBillet.dejaRepondu){
                this.present = reponseBillet.present
                this.accompanied = reponseBillet.accompanied
                this.nom_cavalier = reponseBillet.nom_cavalier
                this.want_a_hotel_room = reponseBillet.veut_une_chambre
                this.startLogementQuizz(reponseBillet.present)
            }

            this.logementQuizzQuestions = []
            let questionnaire = response.data.data.logement;
            for (let i=0;i<questionnaire.questions.length;i++){
                this.logementQuizzQuestions.push(
                    {
                        question:questionnaire.questions[i].libelle,
                        choosedResponse:questionnaire.questions[i].reponses.map((v, i) => ({ i, v })).filter((r)=>  r.v.masReponse == true).map((r)=>r.i),
                        responses:questionnaire.questions[i].reponses.map((r) => ({libelle:r.libelle,slug:r.slug})),
                        slug:questionnaire.questions[i].slug,

                    }
                )
            }

            this.cantHaveLogement = (response.data.data.logement.questions.length > 0)
            this.loaded = true
        })

        // let response = this.$store.state.reposnseInvitation;
        // if (response != ""){
        //     this.present = true
        //     this.accompanied = true
        //     this.nom_cavalier = "pppp"
        //     this.want_a_hotel_room = true
        //    // if (response.dejaRepondu){
        //    //     this.present = response.present
        //    //     this.accompanied = response.accompanied
        //    //     this.nom_cavalier = response.nom_cavalier
        //    //     this.want_a_hotel_room = response.veut_une_chambre
        //    // }
        // }
    }

}
</script>

<style scoped>
.wrapper{
    display: inline-flex;
    background: transparent;
    height: 100px;
    /*width: 400px;*/
    align-items: center;
    justify-content: space-evenly;
    border-radius: 5px;
    padding: 20px 5px;
}
.wrapper .option{
    background: transparent;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    margin: 0 10px;
    /*border-radius: 5px;*/
    cursor: pointer;
    padding: 0 10px;
    border: 2px solid lightgrey;
    transition: all 0.3s ease;
}
.wrapper .option .dot{
    height: 20px;
    width: 20px;
    background: #d9d9d9;
    border-radius: 50%;
    position: relative;
    margin-right: 5px;
}
.wrapper .option .dot::before{
    position: absolute;
    content: "";
    top: 4px;
    left: 4px;
    width: 12px;
    height: 12px;
    background: #11554a;
    border-radius: 50%;
    opacity: 0;
    transform: scale(1.5);
    transition: all 0.3s ease;
}
input[type="radio"]{
    display: none;
}
#option-1:checked:checked ~ .option-1,
#option-2:checked:checked ~ .option-2{
    border-color: #11554a;
    background: #11554a;
}
#option-1:checked:checked ~ .option-1 .dot,
#option-2:checked:checked ~ .option-2 .dot{
    background: #fff;
}
#option-1:checked:checked ~ .option-1 .dot::before,
#option-2:checked:checked ~ .option-2 .dot::before{
    opacity: 1;
    transform: scale(1);
}
.wrapper .option span{
    font-size: 20px;
    color: #808080;
}
#option-1:checked:checked ~ .option-1 span,
#option-2:checked:checked ~ .option-2 span{
    color: #fff;
}
#option-3:checked:checked ~ .option-1,
#option-4:checked:checked ~ .option-2{
    border-color: #11554a;
    background: #11554a;
}
#option-3:checked:checked ~ .option-1 .dot,
#option-4:checked:checked ~ .option-2 .dot{
    background: #fff;
}
#option-3:checked:checked ~ .option-1 .dot::before,
#option-4:checked:checked ~ .option-2 .dot::before{
    opacity: 1;
    transform: scale(1);
}
.wrapper .option span{
    font-size: 20px;
    color: #808080;
}
#option-3:checked:checked ~ .option-1 span,
#option-4:checked:checked ~ .option-2 span{
    color: #fff;
}

#option-5:checked:checked ~ .option-1,
#option-6:checked:checked ~ .option-2{
    border-color: #11554a;
    background: #11554a;
}
#option-5:checked:checked ~ .option-1 .dot,
#option-6:checked:checked ~ .option-2 .dot{
    background: #fff;
}
#option-5:checked:checked ~ .option-1 .dot::before,
#option-6:checked:checked ~ .option-2 .dot::before{
    opacity: 1;
    transform: scale(1);
}
.wrapper .option span{
    font-size: 20px;
    color: #808080;
}
#option-5:checked:checked ~ .option-1 span,
#option-6:checked:checked ~ .option-2 span{
    color: #fff;
}
</style>
