<template>
    <!-- start wpo-portfolio-section -->
    <section class="wpo-portfolio-section-s2 section-padding mt-5" id="gallery">
        <div class="container-fluid">
            <div class="row">
                <div class="wpo-section-title-s2">
                    <div class="section-title-simg">
                        <img src="@/assets/images/section-title2.png" alt="" loading="lazy">
                    </div>
                    <h2 class="mac-gold-one-normal" style="font-family: Parisienne, cursive;text-transform: capitalize;font-weight: bold">{{ title === undefined?"Quelques photos":title }}</h2>
                    <div class="section-title-img ">
                        <div class="round-ball"></div>
                    </div>
                </div>
            </div>
            <div class="sortable-gallery">
                <div class="row">
                    <div class="col-lg-12 mac-mansory-container">
<!--                        <silent-box :gallery="loadedImages" :lazy-loading="true">-->
<!--                            <template v-slot:silentbox-item="{ silentboxItem }">-->
<!--                                <img @load="handleload" @error="handleload" :src="silentboxItem.thumbnail" alt="" class="view-box img img-responsive" style="max-width: 250px!important;border: 15px solid white">-->
<!--                            </template>-->
<!--                        </silent-box>-->
                        <div v-for="(img,key) in loadedImages" v-bind:key="key">
                            <img :src="img?.src" @click="showPicture(img?.src,key)" class="view-box img img-responsive" style="max-width: 250px!important;border: 15px solid white;cursor: pointer">
                        </div>
                    </div>
                </div>
            </div>

        </div> <!-- end container -->
    </section>
    <!-- end wpo-portfolio-section -->
    <div class="row " v-show="imagesToLoad.length>loadedImages.length">
        <div class="submit-area d-flex justify-content-center" style="margin-bottom: 50px">
            <button @click="loadMorePictures()" type="button" class="theme-btn-s4 d-flex">
                <span class="me-2">
                    Charger Plus de photos
                </span>
            </button>
        </div>
    </div>
    <div id="photoView" style="position: fixed;top: 0;left: 0;z-index: 100200;" v-show="photoview.show">
        <div style="width: 100vw;height: 100vh;display: flex;justify-content: center;align-items: center">
            <div id="back" style="background-color: black;opacity: 0.96;width: 100%;height: 100%;" @click="photoview.show = false"></div>
            <div style="position: absolute;">
                <div id="photo-view">
                    <img :src="photoview.src" id="photo-view-img"  style="max-height: 85vh">
                </div>
            </div>
            <div id="close" style="position: absolute;top: 10px;right: 30px;display: none" >
                <i class="fa fa-close" style="font-size: 40px" @click="photoview.show = false"></i>
            </div>
            <div  @click="photoViewPrev()" id="left" style="position: absolute;top: 0;left: 0;height: 100vh;padding: 0px 15px;display: flex;align-items: center;cursor: pointer;">
                <i class="fa fa-caret-left" style="font-size: 50px;color: #ffffff57"></i>
            </div>
            <div @click="photoViewNext()" id="right" style="position: absolute;top: 0;right: 0;height: 100vh;padding: 0px 15px;display: flex;align-items: center;cursor: pointer;">
                <i class="fa fa-caret-right" style="font-size: 50px;color: #ffffff57"></i>
            </div>
            <div @click="downloadImage()" id="download" style="position: absolute;bottom: 7px;border: 2px solid white;padding: 6px 20px;color: white;font-weight: bold;border-radius: 17px;cursor: pointer">
                <i class="fa fa-arrow-down me-2" ></i> Télécharger
            </div>

        </div>
    </div>
</template>

<script>
// import VueLoadImage from 'vue-load-image'
export default {
    name: "SubGallerie",
    props:['images',"title","intro"],
    components: {
        // 'vue-load-image': VueLoadImage
    },
    data:function () {
        return {
            // canGet:true,
            loadedImages:[],
            imagesToLoad:[],
            limit:10,
            loadedNumber:1,
            photoview:{
                show:false,
                src:'http://127.0.0.1:8000/images/evenements/je-sais-pas-wveqprpyivd4zix.jpeg',
                key:''
            }
        }
    },
    computed:{
        canGet:{
            get(){
                //let val = ((this.loadedImages.length !== this.imagesToLoad.length) )
                return true;
            }
        }
    },
    watch:{
      // eslint-disable-next-line no-unused-vars
        images: function (newValue,oldValue) {
            this.imagesToLoad = newValue
            this.loadedImages = []
            for (let i = 0;((i<this.limit)&&(i<this.imagesToLoad.length));i++){

                this.loadedImages[i] = this.imagesToLoad[i]
            }
            // this.canGet = true;
        }
    },
    methods:{
        loadImages:function () {
           if (this.canGet){
               let length = this.loadedImages.length
               for (let i = 0;((i<this.limit)&&(i+length<this.imagesToLoad.length));i++){
                   this.loadedImages[i+length] = this.imagesToLoad[i+length]
               }
               // if (this.loadedImages.length === this.imagesToLoad.length) this.canGet = false
           }
        },
        loadMorePictures:function(){
            this.loadImages()
        },
      // eslint-disable-next-line no-unused-vars
        handleload:function (todo){
            this.loadedNumber++
        },
        showPicture:function (src, key) {
            this.photoview.show = true
            this.photoview.src = src
            this.photoview.key = key
        },
        photoViewNext:function (){
            let key = this.photoview.key+1
            if ((this.loadedImages.length < this.imagesToLoad.length) && !(key < this.loadedImages.length)) {
                this.loadImages();
            }
            if(key<this.loadedImages.length){
                this.photoview.key = key
                document.getElementById('photo-view-img').className += "fadeOut";
                setTimeout(()=> {
                    this.photoview.src = this.loadedImages[key].src
                    document.getElementById('photo-view-img').className = "";
                },500);
            }
        },
        photoViewPrev:function (){
            let key = this.photoview.key-1
            if(key>=0){
                this.photoview.key = key
                document.getElementById('photo-view-img').className += "fadeIn";
                setTimeout(()=> {
                    this.photoview.src = this.loadedImages[key].src
                    document.getElementById('photo-view-img').className = "";
                },500);
            }
        },
        downloadImage:async function () {

            const response = await fetch(this.photoview.src);

            const blobImage = await response.blob();

            const href = URL.createObjectURL(blobImage);

            const anchorElement = document.createElement('a');
            anchorElement.href = href;
            anchorElement.download = this.loadedImages[this.photoview.key].description;

            document.body.appendChild(anchorElement);
            anchorElement.click();

            document.body.removeChild(anchorElement);
            window.URL.revokeObjectURL(href);
        }
    },
    mounted() {
        this.imagesToLoad = this.images
        window.addEventListener("scroll", ()=>{
          // eslint-disable-next-line no-undef
            var position = $(window).scrollTop()+3;
          // eslint-disable-next-line no-undef
            var bottom = $(document).height() - $(window).height()
            if (position > bottom ){
                // this.loadImages();
            }
        })

        this.imagesToLoad = this.images
        this.loadedImages = []
        this.loadImages()

        let touchstartX = 0
        let touchendX = 0
        let el = document.getElementById("photo-view")
        el.addEventListener('touchstart', e => {
            touchstartX = e.changedTouches[0].screenX
        })

        el.addEventListener('touchend', e => {
            touchendX = e.changedTouches[0].screenX
            if (Math.abs(touchendX-touchstartX) > 80){
                if (touchendX < touchstartX) this.photoViewNext()
                if (touchendX > touchstartX) this.photoViewPrev()
            }
        })
    }
}
</script>

<style scoped>
.PhotoSlider__Backdrop{
    background : #000000b3;
}
#photo-view-img {
    opacity:1;
    transition:  1s;
}

#photo-view-img.fadeOut {
    opacity:0;
    transform: translateX(-15px);
    transition: 1s;
}
#photo-view-img.fadeIn {
    opacity:0;
    transform: translateX(15px);
    transition: 1s;
}
.mac-mansory-container{
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 1rem 3rem;
    grid-template-rows: masonry;
}

@media (max-width: 1200px) {
    .mac-mansory-container {
        grid-template-columns: repeat(3, 1fr);
    }
}

@media (max-width: 900px) {
    .mac-mansory-container {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (max-width: 700px) {
    .mac-mansory-container {
        grid-template-columns: repeat(1, 1fr);
    }
}
/*@media (max-width: 450px) {*/
/*    .mac-mansory-container div{*/
/*        grid-template-columns: repeat(1, 1fr);*/
/*    }*/
/*}*/

.mac-mansory-container div {
    display:flex;
    align-items: center;
    justify-content: center;
}
</style>
