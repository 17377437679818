<template>
    <Header></Header>
    <section class="wpo-contact-pg-section" style="padding-top: 170px;">
        <div class="container">
            <div class="row">
                <div class="col col-lg-10 offset-lg-1">
                    <div class="wpo-contact-title"><h2>Veuillez entrer le lien de votre invitation ou alors cliquez dessus et vous serez rediriger ici</h2></div>
                    <div class="wpo-contact-form-area" style="background-color:#11554a">
                        <form method="get" class="contact-validation-active" @submit.prevent="verifierInvitation">
                            <div class="fullwidth">
                                <input type="text" style="color:white"  class="form-control" autofocus v-model="url_invitation">
                            </div>
                            <div class="submit-area d-flex justify-content-center" style="margin-bottom: 50px;">
                                <button type="button" class="theme-btn-s4 d-flex" @click="verifierInvitation">
                                    <span class="me-2">Valider <i v-if="sendingSlug" class="ms-2 fa fa-spinner fa-spin"></i></span>
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <Footer></Footer>
</template>

<script>
import Header from "./SubComponents/Header";
import Footer from "./SubComponents/Footer";
export default {
    name: "GetInvitation",
    components: {Footer, Header},
    data:function(){
        return {
            url_invitation :'',
            sendingSlug:false
        }
    },
    methods:{
        verifierInvitation:function () {
            let url = this.url_invitation;
            let slug = url;
            const regex = /(http|https):\/\/(.*)\/invitation\/(.*)/gm;
            let m;

            while ((m = regex.exec(url)) !== null) {
                if (m.index === regex.lastIndex) {
                    regex.lastIndex++;
                }
                m.forEach((match, groupIndex) => {
                    if (groupIndex === 3) slug = match
                });
            }
            if (slug != ""){
                this.sendingSlug = true
                this.axios.get("/detailInvite/"+slug).then((response) => {
                    let result = response.data;
                    if(result.error != null) {
                        this.$store.commit("showPopup",result.error)
                    }else{
                        if (result.success != null) {
                            this.$store.commit("showPopup", result.success)
                        }else{
                            this.$store.commit("setInvitation",response.data.data)
                            this.$router.push(this.$store.state.nextUrl)
                        }

                    }
                }).catch(error => {
                    if(error.response.status == 500){
                        this.$store.commit("showPopup","Une erreur s'est produite veuillez réessayer plus tard")
                    }else{
                        this.$store.commit("showPopup",error.response.data.errors)
                    }
                }).finally(() => this.sendingSlug = false)
            }
        }
    }
}
</script>

<style scoped>
    input{
        width: 100%;
        margin-bottom: 15px;
        padding-left: 20px;
        height: 60px;
        border: 1px solid #e5e5e5 !important;
        border-radius: 2px;
        border: none;
    }
</style>
